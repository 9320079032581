.ui.grid {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ui.grid.columns {
  height: 66vh !important;
  min-width: 100%;
}

.column-left {
}

.column-right {
  margin: auto;
}

.signin-card {
  padding: 0 !important;
  background-image: url("../../public/img/sign-in-image.jpg") !important;
  background-size: 50% 125% !important;
  background-repeat: no-repeat !important;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}

/* When doubling kicks in for grids */
@media only screen and (max-width: 767px) {
  .ui.grid .ui.stackable.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .signin-card {
    background-image: none !important;
  }
}
