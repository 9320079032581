@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@media only screen and (max-width: 330px) {
  .MuiButtonBase-root.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly.css-118p9r0-MuiButtonBase-root-MuiBottomNavigationAction-root {
    padding-left: 0;
    padding-right: 0;
  }
}

#recTab > .ui.attached.tabular.menu {
  margin-left: -1px;
}

#recTab > .ui.attached.tabular.menu > .active.item {
  border-color: #c4c4c5;
}

.Observations .ui.container {
  margin: 0px !important;
}

.ui.list .list > a.item,
.ui.list > a.item:hover,
a:hover {
  color: #007ACC; /* Changed to Intermentis blue */
}

.recentDropdown .menu {
  max-height: 17rem !important;
}

@media only screen and (max-width: 1120px) {
  header a {
    font-size: 100% !important;
  }
}

#tabs > div > .ui.menu {
  width: auto;
}

#tabs > div > .ui.menu a {
  font-size: 110%;
}

@media (max-width: 768px) {
  /* .content.active {
    padding-left: 0 !important;
    padding-right: 0 !important;
  } */

  #tabs > div > .ui.menu {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MuiBottomNavigationAction-label p {
  font-size: 125%;
}

#confirmMenu ul {
  padding: 0px !important;
  margin: 0px !important;
}

#confirmMenu li {
  background-color: #007ACC; /* Changed to Intermentis blue */
}

.yieldInput input {
  width: 158px !important;
}

.dateInput div input {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.dateInput label {
  margin-left: -0.2rem !important;
}

@media only screen and (max-width: 416px) {
  #tabss .ui.pointing.secondary.menu .item {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}

@media only screen and (max-width: 360px) {
  .yieldInput input {
    width: 124px !important;
  }

  #tabss .ui.pointing.secondary.menu .item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

header {
  font-family: "Poppins", sans-serif;
}

.accordionContent {
  padding: 0px !important;
}

.accordionContent > .ui.container {
  padding: 0px !important;
  margin: 0px !important;
}

.tabs {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  background: white;
  padding: 1rem;
  border: 0.1px solid rgba(0, 0, 0, 0.134);
  border-top: none;
}

* {
  scroll-behavior: smooth;
}

.tab-label {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.inputGrey {
  border: 1px solid #dededf;
}

.darkGrey {
  border: 1px solid #d3d3d3;
}

.noBox {
  padding: 0px !important;
}

.field {
  margin: 0 !important;
}

.recInput {
  margin-bottom: 1rem !important;
}

.errorInput {
  display: flex;
  position: relative;
  flex-direction: column;
}

.errorInput .ui.pointing.above.prompt.label {
  position: absolute;
  bottom: -35px;
  z-index: 10;
}

@layer components {
  .background {
    @apply bg-neutral-200 min-h-[101vh] h-fit flex flex-col items-center;
  }

  .responsive-wrapper {
    @apply lg:w-twothirdscreenw md:w-[70vw] max-w-4xl w-full;
  }

  .tableRow {
    @apply flex justify-between items-center;
  }

  .mobileOnly {
    @apply sm:hidden;
  }

  .desktopOnly {
    @apply hidden sm:block;
  }
}

.shadow-cust {
  box-shadow: -0.1px -0.1px 2px 0px rgba(0, 0, 0, 0.75);
}

#fieldTab {
  min-height: 15rem;
}

.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.logoBlue {
  background: #007ACC; /* Changed to Intermentis blue */
}

.logoDarkBlue {
  background: #333333, /* Changed to Intermentis blue */
}

.logoBlue:hover,
.logoDarkBlue:hover {
  color: white;
}

.bg-white .ui.toggle.checkbox label:before {
  background-color: rgba(0, 0, 0, 0.25);
}

/* Override Semantic UI positive class to use Intermentis blue */
.ui.button.positive {
  background-color: #007ACC !important; /* Intermentis blue */
  color: white !important;
}

.ui.button.positive:hover {
  background-color: #333333 !important; /* Darker blue for hover effect */
  color: white !important;
}

.ui.button.positive:focus {
  background-color: #333333 !important; /* Darker blue for focus effect */
  color: white !important;
}

/* Additional class to ensure specificity */
.ui.button.intermentis-blue {
  background-color: #007ACC !important; /* Intermentis blue */
  color: white !important;
}

.ui.button.intermentis-blue:hover {
  background-color: #333333 !important; /* Darker blue for hover effect */
  color: white !important;
}

.ui.button.intermentis-blue:focus {
  background-color: #333333 !important; /* Darker blue for focus effect */
  color: white !important;
}

.interaction-images-container {
  padding: 20px;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
}

.fullscreen-toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.image-group {
  margin-bottom: 20px;
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 5px;
}

.thumbnail {
  width: 100%;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}
